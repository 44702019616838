<template>
  <div>
    <Header />
    <Mobileheader />
    <h1 class="projects_header" data-aos="fade-up" data-aos-duration="3000">
      PROJECTS
    </h1>
    <div class="small_break"></div>

    <b-container
      class="project_padding_main"
      data-aos="fade-up"
      data-aos-duration="3000"
    >
      <b-row>
        <b-col md="4" lg="4">
          <div class="flip-box">
            <div class="flip-box-inner">
              <div class="flip-box-front">
                <b-img
                  src="@/assets/images/projects/project1.png"
                  class="full-width"
                ></b-img>
              </div>
              <div class="flip-box-back">
                <b-img
                  src="@/assets/images/projects/project2.png"
                  class="full-width"
                ></b-img>
              </div>
            </div>
          </div>
          <div class="product_mobile_small_break1"></div>
          <div class="product_mobile_small_break2"></div>
          <b-container class="project_padding">
            <h4 class="project_sub_heading">
              R & D Laboratory for Bio Medical Technologies, Moratuwa
            </h4>

            <p>
              Premium International – University of Moratuwa R & D Laboratory
              for Bio Medical Technology
            </p>
          </b-container>
        </b-col>
        <div class="small_break"></div>
        <b-col md="4" lg="4">
          <div class="flip-box">
            <div class="flip-box-inner">
              <div class="flip-box-front">
                <b-img
                  src="@/assets/images/projects/project3.png"
                  class="full-width"
                ></b-img>
              </div>
              <div class="flip-box-back">
                <b-img
                  src="@/assets/images/projects/project4.png"
                  class="full-width"
                ></b-img>
              </div>
            </div>
          </div>
          <div class="product_mobile_small_break1"></div>
          <div class="product_mobile_small_break2"></div>
          <b-container class="project_padding">
            <h4 class="project_sub_heading">
              Pharma OSD Manufacturing Facility, Koggala
            </h4>

            <p>
              Premium International – University of Moratuwa R & D Laboratory
              for Bio Medical Technology
            </p>
          </b-container>
        </b-col>
        <div class="small_break"></div>
        <b-col md="4" lg="4">
          <div class="flip-box">
            <div class="flip-box-inner">
              <div class="flip-box-front">
                <b-img
                  src="@/assets/images/projects/project5.png"
                  class="full-width"
                ></b-img>
              </div>
              <div class="flip-box-back">
                <b-img
                  src="@/assets/images/projects/project6.png"
                  class="full-width"
                ></b-img>
              </div>
            </div>
          </div>
          <div class="product_mobile_small_break1"></div>
          <div class="product_mobile_small_break2"></div>
          <b-container class="project_padding">
            <h4 class="project_sub_heading">
              State Pharmaceutical Manufacturing Corporation-Rathmalana
            </h4>

            <p>
              Premium International – University of Moratuwa R & D Laboratory
              for Bio Medical Technology
            </p>
          </b-container>
        </b-col>
        <div class="small_break"></div>
      </b-row>
    </b-container>
    <div class="largebreak"></div>

    <Footer />
  </div>
</template>
<script>
import Header from "./components/header.vue";
import Footer from "./components/Footer.vue";
import Mobileheader from "./components/mobileheader.vue";
import AOS from "aos";
import "aos/dist/aos.css";
import { BImg, BContainer, BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    Footer,
    Header,
    Mobileheader,
    BImg,
    BContainer,
    BRow,
    BCol,
  },
  created() {
    AOS.init();
  },
};
</script>
